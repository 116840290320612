import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getProcessById, isMetaIOGemSpec } from '../../Parser/bindings';
import {
  BaseProcess,
  BaseProcessMetadata,
  BaseState,
  Connection,
  GenericGraph,
  GraphOptionType,
  Metadata
} from '../types';
import { useCurrentGraph } from '../useOriginalGraph';

export function graphToOptions(
  graph: GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>,
  graphName: string = '',
  parent: string = ''
) {
  const graphToOptionsArr: GraphOptionType[] = [];
  const { processes } = graph || { processes: {} };

  Object.entries(processes)?.forEach(([id, node]) => {
    const { x, y, label: metaLabel } = node.metadata;
    const label = graphName ? `${graphName}/${metaLabel}` : metaLabel;
    const returnItem = {
      label,
      value: label,
      type: node.component,
      parent: parent,
      x,
      y
    };
    graphToOptionsArr.push(returnItem);
    if ('processes' in node) {
      graphToOptionsArr.push(...graphToOptions(node, parent ? label : graphName, parent || label)); //if parent is not present use node label, else for all sub nodes use given parent.
    }
  });

  return graphToOptionsArr;
}

export function useSpecs<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>,
  B extends BaseState<G, Metadata>
>() {
  return useSelector<B, B['gemSpecs']>((state) => state.gemSpecs);
}
export function useSpecsWithOutMetaIO<
  G extends GenericGraph<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>,
  B extends BaseState<G, Metadata>
>() {
  const specs = useSelector<B, B['gemSpecs']>((state) => state.gemSpecs);
  return useMemo(() => specs.filter((item) => !isMetaIOGemSpec(item)), [specs]);
}

export function useGetProcessById(processId: string) {
  const { rootGraph, rootPath } = useCurrentGraph();
  const specs = useSpecs();

  return getProcessById(specs, rootGraph, rootPath, processId);
}
