import { Stack } from '@prophecy/ui';
import { GemIconNames } from '@prophecy/ui/Graph/types';
import { ActivityIcon, Icon } from '@prophecy/ui/Icons';
import {
  Hexagon,
  LoosePentagon,
  Pentagon,
  Wing,
  HalfRose,
  DiamondBlue,
  DoubleTerminated,
  TrillionOrange,
  RadiantGreen,
  Dipyramid,
  GemIconProps,
  Dodecahedron,
  HexagonOrange
} from '@prophecy/ui/Icons/GemIcons';
import { BaseColor, getColorCode, theme } from '@prophecy/ui/theme';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { GemType, UISpecCategory } from '../Parser/types';
import { useSpecs } from './graph/nodeUtils';
import { SqlProcessKind } from './graph/types';
import { gemSpecToGemType, gemTypeToString } from './selectors';
import { Entity } from './types/Entity';

const StyledIconWrap = styled(Stack)<{ tone: BaseColor }>`
  ${Icon} {
    font-size: ${theme.sizes.x45};
    color: ${({ tone }) => getColorCode(tone, 600)};
  }
`;

type CategoryUIMeta = {
  iconName: string;
  icon: React.FunctionComponent<GemIconProps>;
  tone: BaseColor;
};

const CommonCategories: Record<string, CategoryUIMeta> = {
  [UISpecCategory.Subgraph]: { iconName: 'Pentagon', icon: Pentagon, tone: BaseColor.blue }
};

export const SparkCategoryDetails: Record<string, CategoryUIMeta> = {
  ...CommonCategories,
  [UISpecCategory.SourceTarget]: { iconName: 'Hexagon', icon: Hexagon, tone: BaseColor.secondary },
  [UISpecCategory.Transform]: { iconName: 'LoosePentagon', icon: LoosePentagon, tone: BaseColor.fuchsia },
  [UISpecCategory.JoinSplit]: { iconName: 'Wing', icon: Wing, tone: BaseColor.purple },
  [UISpecCategory.Custom]: { iconName: 'DoubleTerminated', icon: DoubleTerminated, tone: BaseColor.fuchsia },
  // [UISpecCategory.MyGems]: { iconName: 'HalfRose', icon: HalfRose, tone: BaseColor.pink },
  [UISpecCategory.MachineLearning]: { iconName: 'Dipyramid', icon: Dipyramid, tone: BaseColor.yellow }
};

export function CustomCategoryIcon({ icon, tone }: { icon: React.ReactNode; tone: BaseColor }) {
  return (
    <StyledIconWrap width='100%' height='100%' alignY='center' align='center' tone={tone}>
      {icon}
    </StyledIconWrap>
  );
}

export const ActivityNodeIcon = (props: GemIconProps) => {
  return <CustomCategoryIcon icon={<ActivityIcon type='default' />} tone={BaseColor.orange} />;
};

export const PredefinedGemCategories = {
  [SqlProcessKind.TargetModel]: Entity.Model
};

const SqlCategoryDetails: Record<string, CategoryUIMeta> = {
  //SQL IDE specific categories
  Model: { iconName: 'HalfRose', icon: HalfRose, tone: BaseColor.pink },
  Snapshot: { iconName: 'HalfRose', icon: HalfRose, tone: BaseColor.pink },
  TargetModel: { iconName: 'HalfRose', icon: HalfRose, tone: BaseColor.pink },
  TargetSnapshot: { iconName: 'HalfRose', icon: HalfRose, tone: BaseColor.pink },
  Seed: { iconName: 'Hexagon', icon: Hexagon, tone: BaseColor.secondary },
  Source: { iconName: 'Hexagon', icon: Hexagon, tone: BaseColor.secondary },
  UnreferencedSource: { iconName: 'Hexagon', icon: Hexagon, tone: BaseColor.secondary },
  ExternalSource: { iconName: 'Hexagon', icon: Hexagon, tone: BaseColor.secondary },
  Table: { iconName: 'Hexagon', icon: Hexagon, tone: BaseColor.secondary },
  SingularDataTest: { iconName: 'Activity', icon: ActivityNodeIcon, tone: BaseColor.orange },
  Test: { iconName: 'Activity', icon: ActivityNodeIcon, tone: BaseColor.orange },
  TargetSingularDataTest: { iconName: 'Activity', icon: ActivityNodeIcon, tone: BaseColor.orange }
};

const JobCategoryDetails: Record<string, CategoryUIMeta> = {
  SubFlow: { iconName: 'Pentagon', icon: Pentagon, tone: BaseColor.blue },
  Sensor: {
    icon: Dodecahedron,
    iconName: 'Dodecahedron',
    tone: BaseColor.pink
  },
  Operator: { iconName: 'RadiantGreen', icon: RadiantGreen, tone: BaseColor.secondary },
  'Spark/SQL': { icon: RadiantGreen, iconName: 'RadiantGreen', tone: BaseColor.green_light },
  'Trigger/Notify': { icon: TrillionOrange, iconName: 'TrillionOrange', tone: BaseColor.orange },
  'Data Transfer': {
    icon: HexagonOrange,
    iconName: 'HexagonOrange',
    tone: BaseColor.orange
  }
};

export const CategoryDetails = {
  ...CommonCategories,
  ...SparkCategoryDetails,
  ...SqlCategoryDetails,
  ...JobCategoryDetails
};

export function getGemIcon(category: string = '', pickDefault: boolean = true) {
  return CategoryDetails[category]?.icon || (pickDefault ? DiamondBlue : null);
}

export function getGemIconName(category: string = '') {
  return (CategoryDetails[category]?.iconName || 'DiamondBlue') as GemIconNames;
}

export function getCategoryTone(category: string) {
  return CategoryDetails[category]?.tone || BaseColor.blue;
}

// Utility
export type CategoryMap = Record<string, string>;

export function useCategoryMap() {
  const gemSpecs = useSpecs();
  const categoryMap = useMemo(() => {
    const map: CategoryMap = {};
    gemSpecs.forEach((gem) => {
      const gemType = gemSpecToGemType(gem) as GemType;
      const gemTypeStr = gemTypeToString(gemType);
      map[gemTypeStr] = gem.category;
    });
    return map;
  }, [gemSpecs]);
  return categoryMap;
}
