import { produce, setAutoFreeze } from 'immer';
import { escapeRegExp, isEqual, isObject, omit } from 'lodash-es';
export function produceWithoutFreeze(state, cb) {
    setAutoFreeze(false);
    const nextState = produce(state, cb);
    setAutoFreeze(true);
    return nextState;
}
export function clone(obj) {
    return JSON.parse(JSON.stringify(obj));
}
export function encodeParams(params) {
    const encodedParams = {};
    Object.keys(params).forEach((key) => {
        // decode uri component and then encoding make sure its not double encoded
        encodedParams[key] = encodeURIComponent(decodeURIComponent(params[key]));
    });
    return encodedParams;
}
export const removeEmptyStringFields = (obj) => {
    Object.keys(obj).forEach((k) => {
        const key = k;
        if (obj[key] === '' || obj[key] === null) {
            delete obj[key];
        }
    });
    return obj;
};
export function isObjectLiteral(value) {
    return Object.prototype.toString.call(value) === '[object Object]';
}
export function matchesPropertyPath(propertyPath, pathToMatch) {
    const pathToMatchRegex = new RegExp(`${escapeRegExp(pathToMatch)}${pathToMatch.endsWith('.') ? '' : '($|\\.|\\[)'}`);
    return Boolean(propertyPath.match(pathToMatchRegex));
}
export function getParentPath(path, pathDelimeter = '.') {
    const parentPathParts = path.split(pathDelimeter);
    parentPathParts.pop();
    return parentPathParts.join(pathDelimeter);
}
export function isObjectEqual(obj1, obj2, pathsToIgnore = []) {
    pathsToIgnore.forEach((path) => {
        if (isObject(obj1))
            obj1 = omit(obj1, path);
        if (isObject(obj2))
            obj2 = omit(obj2, path);
    });
    return isEqual(obj1, obj2);
}
export function downloadTextAsFile(filename, text) {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}
