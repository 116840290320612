setTimeout(() => {
    import('./loader');
}, 100);
export { default as EditorBreadcrumb } from './Breadcrumb';
export { default as FileTree } from './FileTree';
export { DiffEditor } from './DiffEditor';
export { default as Editor } from './BaseEditor';
export { MultiFileEditor } from './MultiFileEditor';
export { default as CodeBlock } from './CodeBlock';
export { setMonacoEditorValue } from './BaseEditor/base/hooks';
export * from './LanguageSpecProvider';
