import { theme } from '../theme/index';
export const tokens = {
    CheckboxRoot: {
        s: {
            size: theme.sizes.x16,
            iconSize: theme.fontSizes.x12,
            fontSize: theme.fontSizes.x14,
            borderRadius: theme.radius.xs
        },
        m: {
            size: theme.sizes.x20,
            iconSize: theme.fontSizes.x14,
            fontSize: theme.fontSizes.x16,
            borderRadius: theme.radius.s
        },
        borderThickness: '1.5px',
        HelpText: {
            fontSize: theme.fontSizes.x12,
            color: theme.colors.grey500
        }
    },
    normal: {
        backgroundColor: theme.colors.white,
        color: theme.colors.grey700,
        borderColor: theme.colors.grey300,
        hover: {
            backgroundColor: theme.colors.primary100,
            borderColor: theme.colors.primary500
        },
        focused: {
            borderColor: theme.colors.primary300
        }
    },
    selected: {
        iconColor: theme.colors.white,
        borderColor: theme.colors.primary600,
        backgroundColor: theme.colors.primary600,
        hover: {
            borderColor: theme.colors.primary700,
            backgroundColor: theme.colors.primary700
        }
    },
    disabled: {
        backgroundColor: theme.colors.grey100,
        color: theme.colors.grey300,
        checked: {
            backgroundColor: theme.colors.grey300
        }
    },
    CheckboxGroup: {
        gap: theme.spaces.x12
    }
};
