import { getIndexAndParentPath, PATH_KEY } from '@prophecy/utils/nestedData';
export function isRowExpanded(rowElm, row) {
    var _a;
    return Boolean(rowElm.querySelector('.ui-table-row-expand-icon-expanded') && ((_a = row.children) === null || _a === void 0 ? void 0 : _a.length));
}
export function haveSameParent(rowFromPath, rowToPath) {
    const { parentPath: parentPathFrom } = getIndexAndParentPath(rowFromPath);
    const { parentPath: parentPathTo } = getIndexAndParentPath(rowToPath);
    return parentPathFrom === parentPathTo;
}
export function isCreatableRow(row, index, dataSource) {
    const path = row[PATH_KEY] || `[${index}]`;
    const creatableElementPath = `[${dataSource.length}]`;
    return path === creatableElementPath;
}
