// eslint-disable-next-line no-restricted-syntax
import * as monaco from 'monaco-editor';
import { useState } from 'react';
let setMonacoEditorValue = () => { };
export const useMonacoEditorValue = (editor) => {
    const [editorValue, setEditorValue] = useState('');
    const onEditorValueChange = (value) => {
        setEditorValue(value);
    };
    const setValueAtCursorPosition = (value) => {
        const selection = editor === null || editor === void 0 ? void 0 : editor.getSelection();
        const position = selection === null || selection === void 0 ? void 0 : selection.getStartPosition();
        if (position) {
            const range = new monaco.Range(position.lineNumber, position.column, position.lineNumber, position.column);
            editor === null || editor === void 0 ? void 0 : editor.executeEdits('', [{ range, text: value }]);
            // update local state with the new editor value
            setEditorValue((editor === null || editor === void 0 ? void 0 : editor.getValue()) || '');
            editor === null || editor === void 0 ? void 0 : editor.focus();
        }
    };
    setMonacoEditorValue = setValueAtCursorPosition;
    return { editorValue, onEditorValueChange };
};
export { setMonacoEditorValue };
