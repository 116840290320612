import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import styled from 'styled-components';
import { Stack } from '../Layout';
import { theme } from '../theme';
import { Button } from './Button';
import { tokens } from './tokens';
const StyledGemButton = styled(Button) `
  padding-left: ${tokens.Button.size.s.padding};
  padding-right: ${tokens.Button.size.s.padding};
  height: 38px;
`;
export const GemButton = forwardRef((_a, forwardedRef) => {
    var { children, Icon } = _a, props = __rest(_a, ["children", "Icon"]);
    return (_jsx(StyledGemButton, Object.assign({ size: 'm', variant: 'secondaryGrey' }, props, { ref: forwardedRef, children: _jsxs(Stack, { gap: theme.spaces.x6, direction: 'horizontal', alignY: 'center', children: [_jsx(Icon, { style: { height: '32px', width: '32px' } }), _jsx("span", { children: children })] }) })));
});
