export var SuggestionLanguages;
(function (SuggestionLanguages) {
    SuggestionLanguages["scala"] = "scala";
    SuggestionLanguages["python"] = "python";
    SuggestionLanguages["sql"] = "sql";
})(SuggestionLanguages || (SuggestionLanguages = {}));
export const ExtensionToLanguage = {
    scala: 'scala',
    py: 'python',
    sql: 'sql',
    js: 'javascript',
    json: 'json',
    xml: 'xml',
    yml: 'yaml',
    md: 'markdown',
    txt: 'plaintext'
};
export var FileType;
(function (FileType) {
    FileType["SPEC"] = "Spec";
    FileType["UNKNOWN"] = "Unknown";
    FileType["TEST"] = "Test";
    FileType["UTIL"] = "Util";
})(FileType || (FileType = {}));
export var FileOperation;
(function (FileOperation) {
    FileOperation["ADD"] = "ADD";
    FileOperation["DELETE"] = "DELETE";
    FileOperation["UPDATE"] = "UPDATE";
})(FileOperation || (FileOperation = {}));
export var CompletionItemKind;
(function (CompletionItemKind) {
    CompletionItemKind[CompletionItemKind["Variable"] = 4] = "Variable";
    CompletionItemKind[CompletionItemKind["Function"] = 1] = "Function";
    CompletionItemKind[CompletionItemKind["Operator"] = 11] = "Operator";
})(CompletionItemKind || (CompletionItemKind = {}));
export var CompletionItemInsertTextRule;
(function (CompletionItemInsertTextRule) {
    CompletionItemInsertTextRule[CompletionItemInsertTextRule["InsertAsSnippet"] = 4] = "InsertAsSnippet";
})(CompletionItemInsertTextRule || (CompletionItemInsertTextRule = {}));
export var MarkerSeverity;
(function (MarkerSeverity) {
    MarkerSeverity[MarkerSeverity["Error"] = 8] = "Error";
    MarkerSeverity[MarkerSeverity["Warning"] = 4] = "Warning";
    MarkerSeverity[MarkerSeverity["Info"] = 2] = "Info";
    MarkerSeverity[MarkerSeverity["Hint"] = 1] = "Hint";
})(MarkerSeverity || (MarkerSeverity = {}));
export var SpecFunctionType;
(function (SpecFunctionType) {
    SpecFunctionType["function"] = "function";
    SpecFunctionType["operation"] = "operation";
    SpecFunctionType["udf"] = "udf";
    SpecFunctionType["columnFunction"] = "columnFunction";
})(SpecFunctionType || (SpecFunctionType = {}));
