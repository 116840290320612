import styled, { css } from 'styled-components';
import { hexToTransparent, theme } from '../theme';
import { tokens as tooltipTokens, tooltipAnimation } from '../Tooltip/styled';
import { LINE_HEIGHT, MONACO_TOOLTIP_CLASS } from './tokens';
export const EditorContainer = styled.div `
  height: ${({ height }) => (height ? height : '100%')};
  position: relative;

  .decoration-highlight {
    background: yellow;
  }

  .decoration-warning {
    border-bottom: 2px solid ${theme.colors.warning700};
  }

  .decoration-error {
    border-bottom: 2px solid ${theme.colors.error700};
  }

  .decoration-line-icon {
    cursor: pointer;
    z-index: ${theme.zLayer.l};
  }
  .conflict_current_decoration {
    background: ${theme.colors.secondary200};
  }
  .start_conflict_current_decoration {
    background: ${theme.colors.secondary500};
  }
  .start_conflict_current_after_decoration::after {
    content: ' (Current Change)';
    color: ${theme.colors.grey900};
  }

  .conflict_incoming_decoration {
    background: ${theme.colors.blue200};
  }
  .end_conflict_incoming_decoration {
    background: ${theme.colors.blue500};
  }
  .end_conflict_incoming_after_decoration::after {
    content: ' (Incoming Change)';
    color: ${theme.colors.grey900};
  }

  /**
  * The content widget is causing additional spacing for the editor component on bottom.
  * So moving it to top.
  */
  .overflowingContentWidgets {
    position: absolute;
    top: 0;
  }

  && {
    .editor-widget.suggest-widget {
      .tree {
        margin: 0;
      }
    }
  }

  .diagnostic-decoration-error,
  .diagnostic-decoration-warning {
    display: flex;
    align-items: center;
    padding-left: ${theme.spaces.x8};
    &::after {
      content: '';
      width: ${theme.sizes.x8};
      height: ${theme.sizes.x8};
      border-radius: 50%;
    }
  }

  .diagnostic-decoration-error {
    &::after {
      background-color: ${theme.colors.error500};
    }
  }

  .diagnostic-decoration-warning {
    &::after {
      background-color: ${theme.colors.warning500};
    }
  }
`;
export const StyledPlaceholder = styled.pre `
  color: black;
  font-size: ${theme.fontSizes.x12};
  width: 100%;
  top: 0;
  left: ${(props) => props.left}px;
  right: 0;
  opacity: 0.5;
  position: absolute;
  user-select: none;
  line-height: ${LINE_HEIGHT}px;
`;
export const editorTooltipStyle = css `
  && .${MONACO_TOOLTIP_CLASS} {
    background: ${tooltipTokens.TooltipContent.default.backgroundColor};
    color: ${tooltipTokens.TooltipContent.default.color};
    border: 0;
    border-radius: ${tooltipTokens.TooltipContent.borderRadius};
    ${tooltipAnimation}
  }
`;
export const SliderCSS = css `
  .monaco-scrollable-element > .scrollbar > .slider {
    &,
    &:hover {
      border-radius: ${theme.radius.m};
      background: ${hexToTransparent(theme.colors.grey200, 0.5)}!important;
      &:hover {
        background: ${hexToTransparent(theme.colors.grey300, 0.5)}!important;
      }
    }
  }
`;
