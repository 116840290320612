import { DialogCopilotProps } from '../common/Copilot/types';
import { ProcessKind } from '../common/graph/types';
import { BaseProcess, BaseProcessMetadata, Connection, GenericGraphProcess } from '../common/types';

export type Properties = {
  // We don't need to know the property type before hand
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};
export type BindingType = Record<string, string>;

export enum UISpecCategory {
  'SourceTarget' = 'Source/Target',
  'Transform' = 'Transform',
  'JoinSplit' = 'Join/Split',
  'Custom' = 'Custom',
  // 'MyGems' = 'My Gems',
  'MachineLearning' = 'Machine Learning',
  'Hidden' = 'Hidden',
  'Subgraph' = ProcessKind.Subgraph
}

export enum AirflowUISpecCategory {
  Operator = 'Operator',
  Sensor = 'Sensor',
  TriggerNotify = 'Trigger/Notify',
  SparkSQL = 'Spark/SQL',
  Custom = 'Custom',
  DataTransfer = 'Data Transfer',
  Subgraph = ProcessKind.Subgraph
}

export enum STATE_SCHEMA_TYPES {
  string = 'string',
  int = 'int',
  float = 'float',
  column = 'column',
  boolean = 'boolean',
  struct = 'struct',
  list = 'list',
  scolumn = 'scolumn',
  optional = 'optional',
  union = 'union',
  uiexpression = 'uiexpression',
  expression = 'expression'
}

type PrimitiveSpec = {
  kind: Omit<STATE_SCHEMA_TYPES, 'list' | 'struct'>;
  name: string;
  default?: string | boolean | number | null;
};

export type ListSpec = {
  kind: STATE_SCHEMA_TYPES.list;
  element: Specs;
  name: string;
};

type UnionElementSpec = {
  contains: Specs;
  metadata: unknown;
};

export type UnionSpec = {
  kind: STATE_SCHEMA_TYPES.union;
  name: string;
  elements: UnionElementSpec[];
};

export type OptionalSpec = {
  kind: STATE_SCHEMA_TYPES.optional;
  element: Specs;
};

type Field = {
  name: string;
  contains: Specs;
  required: boolean;
  default?: string | boolean | number | null;
  metadata?: unknown;
};

export type StructSpec = {
  name: string;
  kind: STATE_SCHEMA_TYPES;
  fields: Field[];
};

export type Specs = StructSpec | ListSpec | PrimitiveSpec | UnionSpec | OptionalSpec;

type StateSchema = StructSpec;
export enum GemSpecType {
  ComponentGem = 'ComponentGem',
  MetaComponentGem = 'MetaComponentGem'
}
export type GemSpec = {
  kind: string;
  isBroken?: boolean;
  category: UISpecCategory;
  gemType: GemSpecType;
  uiSpec: UISpecElement;
  defaultState: GenericGraphProcess<unknown, BaseProcessMetadata, BaseProcess<BaseProcessMetadata>, Connection>;
  stateSchema: StateSchema;
  specCode: string;
  uri: string;
  helpDocument?: string;
  gemDescription?: string;
  docUrl?: string;
  componentInfo?: ComponentInfo;
  source: string;
  supportPassThrough?: boolean;
  altProcessLabel?: string;
  visualProperty?: {
    metaGemInterimRowName: string;
  };
  copilot?: DialogCopilotProps;
};

export interface ComponentInfo {
  gemId: string;
  version?: string;
  projectId: string;
  projectName: string;
}

export type GemType = {
  component: string;
  componentInfo?: ComponentInfo;
  category?: string;
};
export type SubGraphSpec = Omit<GemSpec, 'visualProperty'> & {
  originProjectName: string;
  isReadOnly: boolean;
  version: string;
  visualProperty?: {
    reusableSubgraphType: string;
  };
};

export type UISpecElement = {
  kind: string;
  id: string;
  properties?: Properties;
  contains?: UISpecElement[];
};
