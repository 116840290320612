import { AWSAccessProviderType, ConnectorTypeType } from 'frontend/core/src/graphqlTypes/enums';

export enum SPARK_PROVIDER {
  PROPHECY = 'prophecy',
  DATABRICKS = 'databricks',
  LIVY = 'livy',
  EMR = 'emr',
  SYNAPSE = 'synapse',
  DATAPROC = 'dataproc'
}

export enum DataBricksType {
  COMMUNITY = 'community'
}

export enum DatabricksAuthType {
  token = 'token',
  entra = 'entra',
  oidc = 'oidc'
}

export enum JobSizeMode {
  Basic = 'Basic',
  Advanced = 'Advanced'
}

export type JobSizeObj = {
  name: string;
  clusterTemplateSpec: string;
  jobSizeMode?: JobSizeMode;
};

export type LivyJobSizeObj = {
  key?: number;
  name?: string;
  livy: {
    executorCores: number;
    executorMemoryGb: number | string;
    numExecutors: number;
    driverCores: number;
    driverMemoryGb: number | string;
  };
};

export enum ResolutionModes {
  PublicCentral = 'PublicCentral',
  CustomArtifactory = 'CustomArtifactory',
  DBFS = 'DBFS'
}

export enum ProphecyLibsType {
  python = 'pythonProphecyLibs',
  scala = 'scalaProphecyLibs'
}

export type LibraryType = {
  resolutionMode: ResolutionModes;
  dbfsPath?: string;
  artifactoryUrl?: string;
};

type SparkConfEntry = {
  name: string;
  value: string;
};

export type AWSCreds = {
  region?: string;
  accessKeyId?: string;
  secretAccessKey?: string;
  sessionToken?: string;
};

export type AzureCreds = {
  location?: string;
  tenantId?: string;
  clientId?: string;
  clientSecret?: string | null;
  subscriptionId?: string;
  resourceGroupName?: string;
};

export type GCPCreds = {
  projectId: string;
  keyJson: string;
  location: string;
};

export type OIDCInfo = {
  authServerUrl: string;
  resource: string;
  clientId: string;
  clientSecret: string;
  certificate: string;
  privateKey: string;
};

export type DataBricksData = {
  databricks?: {
    url: string;
    token: string;
  };
  clusters: {
    key: string;
    name: string;
  }[];
  jobs: JobSizeObj[];
  selectedCluster?: string;
  timeout?: number;
  driverMachineType?: string;
};

// Fabric Data Utility Types
export type ProphecyManagedData = Omit<DataBricksData, 'jobs'> & {
  cluster_config?: string;
};

// Fabric Data Types
export type DatabricksFabricData = {
  databricksCreds: {
    databricksURL: string;
    databricksToken?: string;
    entraAccessApp?: AzureCreds;
    authType: DatabricksAuthType;
  };
  jobSizesData: JobSizeObj[];
  oidcInfo?: OIDCInfo;
};

export type ProphecyFabricData = Omit<DatabricksFabricData, 'jobSizesData'> & {
  clusterInfo: {
    runtimeVersion: string;
    timeout: number | string;
    ebsVolumeType?: string;
    ebsVolumeCount?: number;
    ebsVolumeSize?: number;
  };
  jobSizesData: JobSizeObj[];
};

type ClusterDetails = {
  proxyUser?: string;
  yarnQueue?: string;
};

export type LivySecretPayload = {
  livy?: ClusterDetails;
};

type LivyAdvancedPayload = {
  url: string;
  auth: string;
  extraJars?: string;
  executionUrl?: string;
  pythonExecutablePath?: string;
  sparkVersion?: string;
  scalaVersion?: string;
  sparkConf?: SparkConfEntry[];
  impersonation?: boolean;
  bearerToken?: string;
  mtlsCredentials?: {
    clientCertificate?: string;
    clientKey?: string;
  };
};

type LivyPayload = {
  executorMemoryInGb?: number;
  executorCores?: number;
  impersonation?: boolean;
  oidcInfo?: OIDCInfo;
  creds: LivySecretPayload;
  advanced: LivyAdvancedPayload;
};

export type LivyFabricData = LivyPayload & {
  jobSizesData: LivyJobSizeObj[];
};

type EMRSecretPayload = {
  aws: AWSCreds;
  livy?: ClusterDetails;
};

type EMRPayload = {
  clusterId: string;
  bucket: string;
  sparkVersion?: string;
  livyUri?: string;
  oidcInfo?: OIDCInfo;
  arn?: string;
  creds: EMRSecretPayload;
  advanced: LivyAdvancedPayload;
  clusterDetails?: ClusterDetails;
  serverless?: boolean;
};

export type EMRFabricData = EMRPayload & {
  jobSizesData: LivyJobSizeObj[];
};

export type SynapseSecretPayload = {
  azure: AzureCreds;
  livy?: ClusterDetails;
};

type SynapsePayload = {
  name: string;
  workspaceName: string;
  sparkPoolName: string;
  sparkVersion: string;
  livyUri: string;
  container: string;
  creds: SynapseSecretPayload;
  advanced: LivyAdvancedPayload;
  clusterDetails?: ClusterDetails;
};

export type SynapseFabricData = SynapsePayload & {
  jobSizesData: LivyJobSizeObj[];
};

export type DataprocSecretPayload = {
  gcp: GCPCreds;
  livy?: ClusterDetails;
};

type DataprocPayload = {
  name: string;
  clusterName: string;
  sparkVersion?: string;
  livyUri?: string;
  bucket: string;
  creds: DataprocSecretPayload;
  advanced: LivyAdvancedPayload;
  clusterDetails?: ClusterDetails;
};

export type DataprocFabricData = DataprocPayload & {
  jobSizesData: LivyJobSizeObj[];
};

export type FabricData =
  | ProphecyFabricData
  | DatabricksFabricData
  | LivyFabricData
  | EMRFabricData
  | SynapseFabricData
  | DataprocFabricData;

// Fabric Payload Types
export type SparkProviderInfo = {
  providerType?: SPARK_PROVIDER;
  databricks?: {
    cloud: string;
    url: string;
    entraAccessApp?: AzureCreds;
    authType?: DatabricksAuthType;
    creationInfo?: {
      version: string;
      autoTerminationTimeout: string;
      aws?: {
        ebsVolumeType?: string;
        ebsVolumeCount?: number | string;
        ebsVolumeSize?: number | string;
      };
    };
  };
  livy?: Omit<LivyPayload, 'advanced' | 'creds'> & LivyAdvancedPayload;
  emr?: Omit<EMRPayload, 'creds'> & { creds: EMRPayload['creds']['aws'] };
  synapse?: Omit<SynapsePayload, 'creds'> & { creds: SynapsePayload['creds']['azure'] };
  dataproc?: Omit<DataprocPayload, 'creds'> & { creds: DataprocPayload['creds']['gcp'] };
};

export type FabricServicePrincipalState = {
  expiry: string;
};

export type UserAllFabricInfo = {
  livy?: ClusterDetails;
  aws?: AWSCreds;
  azure?: AzureCreds;
  databricks?: {
    token?: string;
  };
  gcp?: GCPCreds;
};

// Resolution Mode types
export type PublicCentral_Type = { [ResolutionModes.PublicCentral]: {} };
export type CustomArtifactory_Type = { [ResolutionModes.CustomArtifactory]: { url: string } };
export type DBFS_Type = { [ResolutionModes.DBFS]: { path: string } };
export type ResolutionModesType = PublicCentral_Type | CustomArtifactory_Type | DBFS_Type;
export type ResolutionModesResponse = Record<ProphecyLibsType, ResolutionModesType>;

export interface JobSizePool {
  type: string;
  pool: {
    instance_pool_id: string;
    instance_pool_name: string;
    node_type_id: string;
    min_idle_instances: number;
    state: string;
    stats: {
      used_count: number;
      idle_count: number;
      pending_used_count: number;
      pending_idle_count: number;
    };
    status: {};
    max_capacity: number;
    default_tags: {
      Vendor: string;
      DatabricksInstancePoolCreatorId: string;
      DatabricksInstancePoolId: string;
      DatabricksInstanceGroupId: string;
    };
    idle_instance_autotermination_minutes: number;
    enable_elastic_disk: boolean;
  };
}

export type WorkerDriverNode = {
  nodeType: {
    category: string;
    node_type_id: string;
    memory_mb: number;
    num_cores: number;
    description: string;
    instance_type_id: string;
    is_deprecated: boolean;
    node_info?: {
      status?: string[];
      available_core_quota: number;
      total_core_quota: number;
    };
    node_instance_type: {
      instance_type_id: string;
      local_disks: number;
      local_disk_size_gb: number;
      instance_family: string;
      swap_size: string;
      is_graviton: boolean;
    };
    photon_worker_capable: boolean;
    photon_driver_capable: boolean;
    is_graviton: boolean;
  };
  type: string;
};

export type SparkVersion = {
  key: string;
  name: string;
  parent?: string;
};

export type PolicyConfig = {
  workerNodes: {
    [key: string]: Array<WorkerDriverNode>;
  } & {
    Pools: Array<JobSizePool>;
  };
  driverNodes: {
    [key: string]: Array<WorkerDriverNode>;
  } & {
    Pools: Array<JobSizePool>;
  };
  sparkVersions: {
    [key: string]: Array<SparkVersion>;
  };
};

export type DatabricksPolicy = {
  id?: string;
  name: string;
  definition: string;
  config?: PolicyConfig;
};

export type ClusterConfig = {
  id: string;
  name: string;
  clusterCreationRequest: string;
};

export type InstanceProfile = {
  instance_profile_arn: string;
  is_meta_instance_profile: boolean;
};

export enum DatabricksType {
  GCP = 'gcp',
  AWS = 'aws',
  Azure = 'azure'
}

export type DatabricksMetaInfo = {
  isUCEnabled: boolean;
  dbType: DatabricksType;
  policies: DatabricksPolicy[];
  instanceProfiles: InstanceProfile[];
  unrestrictedPolicy?: DatabricksPolicy;
  defaultJobSize: {
    default: string;
  };
};

export type DBJobSize = {
  policy_id?: string;
  data_security_mode: string;
  spark_version: string;
  runtime_engine: string;

  instance_pool_id?: string | null;
  node_type_id?: string | null;
  driver_instance_pool_id?: string | null;
  driver_node_type_id?: string | null;
  num_workers?: number | null;
  autoscaleMinWorkers?: number | null;
  autoscaleMaxWorkers?: number | null;

  autoscale?: {
    min_workers: number;
    max_workers: number;
  };
  enable_elastic_disk?: boolean;
  autotermination_minutes?: number;
  custom_tags: Record<string, string>;

  aws_attributes?: {
    first_on_demand: number;
    availability: string;
    zone_id: string;
    spot_bid_price_percent: number;
    ebs_volume_type?: string;
    ebs_volume_count?: number;
    ebs_volume_size?: number;
    instance_profile_arn?: string;
  };

  gcp_attributes?: {
    use_preemptible_executors: boolean;
    availability: string;
    zone_id: string;
    google_service_account?: string;
  };

  azure_attributes?: {
    first_on_demand: number;
    availability: string;
    spot_bid_max_price: number;
  };

  cluster_log_conf?: {
    dbfs?: {
      destination: string;
    };
    s3?: {
      destination: string;
      region: string;
      enable_encryption?: boolean;
      canned_acl?: string;
    };
  };

  init_scripts?: Array<{
    dbfs?: {
      destination: string;
    };
    workspace?: {
      destination: string;
    };
    gcs?: {
      destination: string;
    };
    abfss?: {
      destination: string;
    };
    s3?: {
      destination: string;
      region: string;
    };
    volumes?: {
      destination: string;
    };
  }>;

  ssh_public_keys?: string[];
  spark_conf: Record<string, string>;
  spark_env_vars?: Record<string, string>;

  metainfo?: {
    jobMode: string;
    custom_tags: Record<string, string>;
    passthrough: boolean;
    default_spark_conf: Record<string, string>;
    sparkVersionMeta: {
      isGPU?: boolean;
      version: string;
    };
    workerMeta: {
      memory_mb: number;
      num_cores: number;
      node_type_id: string;
      isGPU?: boolean;
      available_core_quota?: number;
      local_disks?: number;
      is_graviton?: boolean;
    };
    driverMeta: {
      memory_mb: number;
      num_cores: number;
      node_type_id: string;
      isGPU?: boolean;
      available_core_quota?: number;
      local_disks?: number;
      is_graviton?: boolean;
    };
  };
};

export type SparkEnvironment = {
  id: string;
  name: string;
  state: string;
  canConnect: boolean;
  livyUri: string;
  sparkVersion: string;
  workspaceName?: string;
  sparkPoolName?: string;
  sizes?: Record<string, number>;
  serverless?: boolean;
};

export type DatabricksConnection = {
  uid: string;
  ownerUID?: string;
  name: string;
  description?: string;
  cron: string;
  enabled: boolean;
  connectorType: ConnectorTypeType;

  jdbcUrl?: string;
  token?: string;
  workspaceUrl?: string;

  accessKey?: string;
  secretKey?: string;
  region?: string;
  awsAccessProvider?: AWSAccessProviderType;
  arn?: string;
};

export type ARNRole = { id: string; name: string };

export type SparkEnvironmentResponse = { environments: SparkEnvironment[]; roles: ARNRole[] };
