import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from '../Button';
import { AlertTriangleIcon, CheckCircleBrokenIcon, InfoCircleIcon, XCloseIcon } from '../Icons';
import { StackItem } from '../Layout';
import { renderWithTooltip } from '../Tooltip';
import { LabelWithHint } from '../Typography/LabelWithHint';
import { StyledAlert } from './styled';
import { tokens } from './tokens';
import { Variant } from './types';
const IconMap = {
    warning: _jsx(AlertTriangleIcon, { type: 'default', color: tokens.Alert.warning.iconColor }),
    error: _jsx(AlertTriangleIcon, { type: 'default', color: tokens.Alert.error.iconColor }),
    info: _jsx(InfoCircleIcon, { type: 'default', color: tokens.Alert.info.iconColor }),
    success: _jsx(CheckCircleBrokenIcon, { type: 'default', color: tokens.Alert.success.iconColor })
};
export function Alert(_a) {
    var { variant = Variant.warning, banner, children, icon = IconMap[variant], showIcon = true, closable, onClose, tooltip, hint } = _a, restProps = __rest(_a, ["variant", "banner", "children", "icon", "showIcon", "closable", "onClose", "tooltip", "hint"]);
    let alertUI = (_jsxs(StyledAlert, Object.assign({ variant: variant, direction: 'horizontal', alignY: 'top', banner: banner, role: 'alert' }, restProps, { children: [showIcon && _jsx("span", { children: icon }), _jsx(StackItem, { grow: '1', children: children }), closable && _jsx(Button, { variant: 'plain', icon: _jsx(XCloseIcon, { type: 'default' }), onClick: onClose })] })));
    if (tooltip) {
        alertUI = _jsx(_Fragment, { children: renderWithTooltip(alertUI, tooltip) });
    }
    if (hint) {
        alertUI = _jsx(LabelWithHint, { hint: hint, children: alertUI });
    }
    return alertUI;
}
