import { format } from 'timeago.js';
export function toFormattedTime(time, { inSeconds } = { inSeconds: false }) {
    if (time < 60 * 1000)
        return Math.round(time / 1000) + ' seconds';
    return format(Date.now() + time * (inSeconds ? 1000 : 1)).replace('in ', '');
}
const MORNING_START_TIME = 0;
const AFTERNOON_START_TIME = 12;
const EVENING_START_TIME = 18;
export function getDayPart() {
    const currentHour = new Date().getHours();
    if (currentHour >= MORNING_START_TIME && currentHour < AFTERNOON_START_TIME) {
        return 'Morning';
    }
    else if (currentHour >= AFTERNOON_START_TIME && currentHour < EVENING_START_TIME) {
        return 'Afternoon';
    }
    return 'Evening';
}
export const formattedDateTime = (value, options = {}) => {
    return new Date(value).toLocaleString('en', options);
};
const twoDigitNumber = (value) => {
    return value < 10 ? `0${value}` : value;
};
export const formattedRelativeDateTime = (value, showDate = true, ampmMap = { am: 'AM', pm: 'PM' }) => {
    const date = new Date(value);
    const hours = date.getHours();
    let hr = hours % 12;
    if (hr === 0)
        hr = 12;
    const dayTime = hours < 12 ? ampmMap.am : ampmMap.pm;
    const dateString = date.toDateString().split(' ');
    dateString.shift();
    return `${twoDigitNumber(hr)}:${twoDigitNumber(date.getMinutes())} ${dayTime}${showDate ? ` ${dateString.join(' ')}` : ''}`;
};
export const addHours = (hours, date) => {
    let ms = hours * 60 * 60 * 1000;
    date.setTime(date.getTime() + ms);
    return date;
};
export const subtractMonths = (date, months) => {
    const currentDate = new Date(date);
    currentDate.setMonth(currentDate.getMonth() - months);
    return currentDate;
};
export const subtractYears = (date, years) => {
    const currentDate = new Date(date);
    currentDate.setFullYear(currentDate.getFullYear() - years);
    return currentDate;
};
export const subtractDays = (days, date) => {
    const currentDate = new Date(date);
    let ms = days * 24 * 60 * 60 * 1000;
    currentDate.setTime(currentDate.getTime() - ms);
    return currentDate;
};
export const subtractHours = (hours, date) => {
    const currentDate = new Date(date);
    let ms = hours * 60 * 60 * 1000;
    currentDate.setTime(currentDate.getTime() - ms);
    return currentDate;
};
export const addMinutes = (minutes, date) => {
    let ms = minutes * 60 * 1000;
    date.setTime(date.getTime() + ms);
    return date;
};
export const subTractMinutes = (date, minutes) => {
    const currentDate = new Date(date);
    let ms = minutes * 60 * 1000;
    currentDate.setTime(currentDate.getTime() - ms);
    return currentDate;
};
export function getDateStringXDaysFromNow(x) {
    const currentDate = new Date();
    const targetDate = new Date(currentDate.getTime() + x * 24 * 60 * 60 * 1000);
    const tiemStamp = targetDate.getTime();
    return formattedRelativeDateTime(tiemStamp);
}
// supports 'dd', 'mm', 'yy', 'yyyy'
export const getDateRegex = (format) => {
    const monRegFeb = '02';
    const dateRegFeb = (isLeapYear) => `(?:0[1-9]|1[0-9]|2[0-${isLeapYear ? '9' : '8'}])`;
    const monReg30Day = '(?:0[469]|11)';
    const dateReg30Day = '(?:0[1-9]|[12][0-9]|30)';
    const monReg31Day = '(?:0[13578]|1[02])';
    const dateReg31Day = '(?:0[1-9]|[12][0-9]|3[01])';
    const leapYearReg = (isFull) => `(?:${isFull ? '19' : ''}(?:8[048]|[79][26])|${isFull ? '2[0-9]' : ''}(?:[02468][048]|[13579][26]))`;
    const nonLeapYearReg = (isFull) => 
    // prettier-ignore
    `(?:${isFull ? '19' : ''}(?:8[1-35-79]|[79][013-57-9])|${isFull ? '2[0-9]' : ''}(?:[02468][1-35-79]|[13579][013-57-9]))`;
    const formatToRegex = (isLeapYear) => {
        return format
            .replace('yyyy', isLeapYear ? leapYearReg(true) : nonLeapYearReg(true))
            .replace('yy', isLeapYear ? leapYearReg() : nonLeapYearReg())
            .replace(/mm(.*?)dd/, 
        // prettier-ignore
        `(?:(?:${monReg30Day}$1${dateReg30Day})|(?:${monReg31Day}$1${dateReg31Day})|(?:${monRegFeb}$1${dateRegFeb(isLeapYear)}))`)
            .replace(/dd(.*?)mm/, 
        // prettier-ignore
        `(?:(?:${dateReg30Day}$1${monReg30Day})|(?:${dateReg31Day}$1${monReg31Day})|(?:${dateRegFeb(isLeapYear)}$1${monRegFeb}))`);
    };
    const leapDateReg = formatToRegex(true);
    const nonLeapDateReg = formatToRegex();
    return new RegExp(`^(?:${leapDateReg}|${nonLeapDateReg})$`);
};
export const getDaysAgo = (time) => {
    const noOfDaysElapsed = Math.ceil((Date.now() - time) / (1000 * 60 * 60 * 24));
    switch (noOfDaysElapsed) {
        case 1:
            return 'Today';
        case 2:
            return 'Yesterday';
        default:
            return `${noOfDaysElapsed} days`;
    }
};
export const getDuration = (endTime, startTime) => {
    let seconds = Math.floor((endTime - startTime) / 1000);
    let minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    let durationStr = '';
    seconds = seconds % 60;
    minutes = minutes % 60;
    if (hours > 0)
        durationStr += `${hours}h `;
    if (minutes > 0)
        durationStr += `${minutes}m `;
    if (seconds > 0)
        durationStr += `${seconds}s`;
    if (hours === 0 && minutes === 0 && seconds === 0)
        durationStr += '< 1s';
    return durationStr;
};
